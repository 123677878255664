// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { forwardRef, Fragment, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import PageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { DocumentTitle } from '@atlassian/jira-global-document-title/src/DocumentTitle.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { isCustomFilter } from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import { DetailsPopup } from '@atlassian/jira-issue-navigator-custom-filters/src/ui/details-popup/index.tsx';
import { DetailsButtonHeaderWrapper } from '@atlassian/jira-issue-navigator-custom-filters/src/ui/details-popup/styled.tsx';
import {
	FavoriteButton,
	FavoriteButtonHeaderWrapper,
} from '@atlassian/jira-issue-navigator-custom-filters/src/ui/favourite-button/index.tsx';
import type { CustomHeaderProps } from '@atlassian/jira-issue-navigator/src/common/types.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import messages from './messages.tsx';
import PageActions from './page-actions/index.tsx';

export const FilterTitleTooltipTag = forwardRef<
	HTMLSpanElement,
	{
		children?: ReactNode;
	}
>((props, ref) => <FilterTitleTooltipTagStyled ref={ref} {...props} />);

export const Header = ({
	jql,
	filterId,
	filterDetails,
	searchResultPageData,
}: CustomHeaderProps) => {
	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();

	const titleMessage =
		filterId !== undefined && filterDetails
			? formatMessage(messages.filterWindowTitle, {
					filterName: filterDetails.name,
				})
			: formatMessage(messages.windowTitle);

	const heading = formatMessage(messages.heading);

	return (
		<PageHeader
			actions={
				<PageActions
					jql={jql}
					filterId={filterId}
					isModifiedFilter={filterDetails?.jql !== jql}
					searchResultPageData={searchResultPageData}
					filterName={filterDetails?.name}
				/>
			}
		>
			<DocumentTitle title={titleMessage} />
			<HeaderContentWrapper>
				{filterId !== undefined && filterDetails ? (
					<>
						<Tooltip content={filterDetails.name} tag={FilterTitleTooltipTag} position="mouse">
							<FilterTitleTooltipContent>{filterDetails.name}</FilterTitleTooltipContent>
						</Tooltip>
						{!isAnonymous && (
							<FavoriteButtonHeaderWrapper>
								<FavoriteButton
									filterId={filterDetails.filterId}
									isFavoriteInitial={filterDetails.isFavourite}
									favoriteItemName={filterDetails.name}
								/>
							</FavoriteButtonHeaderWrapper>
						)}
						{isCustomFilter(filterDetails.filterId) && (
							<DetailsButtonHeaderWrapper>
								<DetailsPopup filterId={filterId} />
							</DetailsButtonHeaderWrapper>
						)}
					</>
				) : (
					heading
				)}
			</HeaderContentWrapper>
		</PageHeader>
	);
};

export default Header;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FilterTitleTooltipTagStyled = styled.span({
	display: 'flex',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FilterTitleTooltipContent = styled.span({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HeaderContentWrapper = styled.span({
	display: 'inline-flex',
	alignItems: 'flex-start',
	font: token('font.heading.large'),
	maxWidth: '100%',
});
