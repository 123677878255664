import React, { forwardRef } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import {
	FilterButton,
	type FilterButtonProps,
} from '@atlassian/jira-issue-navigator-actions-common/src/ui/filter-button/index.tsx';
import messages from './messages.tsx';

type PluggableItemsFilterButtonProps = Omit<FilterButtonProps, 'text' | 'label'> & {
	text?: string;
	label?: string;
};

export const PluggableItemsFilterButton = forwardRef<HTMLElement, PluggableItemsFilterButtonProps>(
	(props, ref) => {
		const { formatMessage } = useIntl();
		return (
			<FilterButton
				testId="issue-navigator-action-pluggable-items.ui.filter-button.menu-trigger"
				vcId="pluggable-items"
				text={formatMessage(messages.pluggableItemsButtonText)}
				label={formatMessage(messages.pluggableItemsButtonIconLabel)}
				{...props}
				ref={ref}
			/>
		);
	},
);
