import React from 'react';
import { LazySuspense, lazyForPaint } from '@atlassian/react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import {
	useDebuggerStatus,
	useJqlDebuggerStoreActions,
	useIsAiEnabled,
} from '../controllers/jql-debugger-data/index.tsx';
import { ERROR_BOUNDARY_IDS, PACKAGE_NAME_JQL_DEBUGGER, TEAM_NAME } from '../common/constants.tsx';

export const JQLDebuggerRenderSlotAsync = lazyForPaint(
	() => import(/* webpackChunkName: "jql-debugger-content-modal" */ './index'),
);

export function JQLDebuggerRenderSlot() {
	const [debuggerState] = useDebuggerStatus();
	const debuggerActions = useJqlDebuggerStoreActions();
	const [isAiEnabled] = useIsAiEnabled();

	// Don't render the render slot when AI is not enabled
	if (!isAiEnabled) {
		return null;
	}

	// Don't render the render slot when the feature is at initial state
	if (debuggerState === 'INIT') {
		return null;
	}

	return (
		<JSErrorBoundary
			packageName={PACKAGE_NAME_JQL_DEBUGGER}
			id={ERROR_BOUNDARY_IDS.FIX_WITH_AI_MODAL}
			teamName={TEAM_NAME}
			fallback="unmount"
			onError={() => {
				// On error, set the feature state to be JS_ERROR, so that such scenarios can be handled without
				// interfering with the feature initial state nor with the AI event instrumentation
				debuggerActions.setStatus('JS_ERROR');
			}}
		>
			{/* Since this is just a render slot, we render nothing as the suspense fallback */}
			<LazySuspense fallback={null}>
				<JQLDebuggerRenderSlotAsync />
			</LazySuspense>
		</JSErrorBoundary>
	);
}
